import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Immendorf: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Immendorf,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Immendorf"
          d="M498.6,1028.5c-0.6,0.2-2,0.7-2.6,0.8v-78.2l28,8.9l2-22l36,16l0,100.1l-0.1,0.6 c-11.2,4.5-22.7,12.7-33.8,17.5c-7.2-14.7-18.3-30.3-25.7-44.9C501.4,1027.6,499.6,1028.2,498.6,1028.5z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 508.5845 997.3232)"
        >
          <tspan x="0" y="0">
            Immen-{' '}
          </tspan>
          <tspan x="0" y="14.4">
            dorf
          </tspan>
        </text>
      </g>
    </>
  );
});
